var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white px-4 py-4 rounded-lg flex flex-col gap-4"},[_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('div',{staticClass:"flex gap-4"},[_vm._t("beforeToggler"),(_vm.showToggle && _vm.value?.length > 0)?_c('button',{attrs:{"type":"button","disabled":_vm.open},on:{"click":_vm.showForms}},[_c('MultientityIcon',{attrs:{"type":"openForms","fill":_vm.buttonFillColor}})],1):_vm._e()],2),(_vm.open)?_c('button',{attrs:{"type":"button"},on:{"click":_vm.hideForms}},[_c('MultientityIcon',{attrs:{"type":"closeForms","fill":"red"}})],1):_vm._e()]),(_vm.open)?_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"flex flex-col gap-4"},[_vm._l((_vm.parsedData),function(item){return _c('div',{key:item.data.id,attrs:{"id":`dcr-form-${item.data.id}`}},[_c('CollectInfoForm',{key:item.data.id,ref:`dcr-form-${item.data.id}`,refInFor:true,attrs:{"value":item?.data || null,"config":_vm.formConfig},on:{"input":function($event){return _vm.updateDataCollection($event, item)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-end px-5 pb-5"},[(_vm.scheduledSequences[item?.data?.id])?_c('email-scheduler',{staticClass:"cursor-pointer",attrs:{"item":_vm.scheduledSequences[item?.data?.id]}}):_vm._e()],1)]},proxy:true}],null,true)})],1)}),(_vm.showNewFormAdding)?_c('CollectInfoForm',{key:"newForm",attrs:{"config":_vm.newFormConfig},on:{"onHeaderBtnClick":_vm.removeNewForm},model:{value:(_vm.newFormModel),callback:function ($$v) {_vm.newFormModel=$$v},expression:"newFormModel"}}):_vm._e()],2),(_vm.showActionButtons)?_c('div',{staticClass:"flex justify-end gap-4 mt-4"},[_c('button',{staticClass:"flex items-center gap-2 p-3 font-bold cursor-pointer rounded-lg border-2",class:{
                    'text-primary-500 border-primary-500': !_vm.showNewFormAdding,
                    'text-gray-400 border-gray-300 cursor-none': _vm.showNewFormAdding
                },attrs:{"disabled":_vm.showNewFormAdding},on:{"click":_vm.addNewForm}},[_c('MultientityIcon',{attrs:{"width":"20","height":"20","type":"addForm","fill":_vm.svgFillColor}}),_c('span',[_vm._v("Add")])],1),_c('button',{staticClass:"p-3 font-bold rounded-lg",class:{
                    'text-white bg-primary-500 cursor-pointer': !_vm.saveButtonDisabled,
                    'text-gray-400 bg-gray-300 cursor-none ': _vm.saveButtonDisabled
                },attrs:{"disabled":_vm.saveButtonDisabled},on:{"click":_vm.saveForms}},[_vm._v(" Save ")])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
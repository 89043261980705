<template>
    <div class="flex items-center mb-4 rounded-lg bg-white entity-toolbar w-full h-16">
      <div
        :class="caseEntityItemClass"
        @click="onEntityItemClick(caseEntity)"
      >
          <span>{{ caseEntity.name }}</span>
          <!-- <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 0C0.784766 0 0 0.784766 0 1.75V12.25C0 13.2152 0.784766 14 1.75 14H10.5C11.4652 14 12.25 13.2152 12.25 12.25V1.75C12.25 0.784766 11.4652 0 10.5 0H1.75ZM1.75 3.0625C1.75 2.82188 1.94687 2.625 2.1875 2.625H3.0625C3.30312 2.625 3.5 2.82188 3.5 3.0625V3.9375C3.5 4.17812 3.30312 4.375 3.0625 4.375H2.1875C1.94687 4.375 1.75 4.17812 1.75 3.9375V3.0625ZM1.75 6.5625C1.75 6.32188 1.94687 6.125 2.1875 6.125H3.0625C3.30312 6.125 3.5 6.32188 3.5 6.5625V7.4375C3.5 7.67812 3.30312 7.875 3.0625 7.875H2.1875C1.94687 7.875 1.75 7.67812 1.75 7.4375V6.5625ZM2.1875 9.625H3.0625C3.30312 9.625 3.5 9.82187 3.5 10.0625V10.9375C3.5 11.1781 3.30312 11.375 3.0625 11.375H2.1875C1.94687 11.375 1.75 11.1781 1.75 10.9375V10.0625C1.75 9.82187 1.94687 9.625 2.1875 9.625ZM5.25 10.5C5.25 10.2594 5.44688 10.0625 5.6875 10.0625H10.0625C10.3031 10.0625 10.5 10.2594 10.5 10.5C10.5 10.7406 10.3031 10.9375 10.0625 10.9375H5.6875C5.44688 10.9375 5.25 10.7406 5.25 10.5ZM5.6875 3.0625H10.0625C10.3031 3.0625 10.5 3.25938 10.5 3.5C10.5 3.74062 10.3031 3.9375 10.0625 3.9375H5.6875C5.44688 3.9375 5.25 3.74062 5.25 3.5C5.25 3.25938 5.44688 3.0625 5.6875 3.0625ZM5.25 7C5.25 6.75938 5.44688 6.5625 5.6875 6.5625H10.0625C10.3031 6.5625 10.5 6.75938 10.5 7C10.5 7.24062 10.3031 7.4375 10.0625 7.4375H5.6875C5.44688 7.4375 5.25 7.24062 5.25 7Z" fill="#0D69D5"/>
          </svg> -->
      </div>
        <div class="flex items-center w-full overflow-hidden gap-2 px-2">
          <div class="rounded-lg w-9 h-full border-2 p-3 flex justify-center ml-2 cursor-pointer" @click="scrollLeft">
            <font-awesome-icon icon="chevron-left" class="text-primary-500 h-6" />
          </div>

          <div class="flex items-center gap-2 py-2 scrollablecontent w-full">
            <div
              v-for="item in items"
              :key="item.id"
              class="flex items-center justify-center gap-6 min-h-16 px-7 py-3 text-blue-500 cursor-pointer entity-toolbar__item"
              :class="{ 'entity-toolbar__item--isActive': activeItemId === item.id }"
              @click="onEntityItemClick(item)"
            >
              <div class="relative z-10" :class="item?.name && 'mb-1'">
                <div class="flex items-center gap-2">
                  <span class="flex items-center justify-center w-5 h-5 rounded-full bg-primary-500">
                    <component
                      :is="item.component"
                      fill="#fff"
                    />
                  </span>
                  <span class="text-xs font-bold text-base-content-300">{{ capitalize(item?.entity_type) }}</span>
                </div>
                <p
                  v-if="item?.name"
                  class="mt-1 text-sm font-bold text-base-content w-48 truncate"
                  :title="item?.name"
                >
                  {{ item?.name }}
                </p>
              </div>

              <div class="relative z-10">
                <!-- <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.75 0C0.784766 0 0 0.784766 0 1.75V12.25C0 13.2152 0.784766 14 1.75 14H10.5C11.4652 14 12.25 13.2152 12.25 12.25V1.75C12.25 0.784766 11.4652 0 10.5 0H1.75ZM1.75 3.0625C1.75 2.82188 1.94687 2.625 2.1875 2.625H3.0625C3.30312 2.625 3.5 2.82188 3.5 3.0625V3.9375C3.5 4.17812 3.30312 4.375 3.0625 4.375H2.1875C1.94687 4.375 1.75 4.17812 1.75 3.9375V3.0625ZM1.75 6.5625C1.75 6.32188 1.94687 6.125 2.1875 6.125H3.0625C3.30312 6.125 3.5 6.32188 3.5 6.5625V7.4375C3.5 7.67812 3.30312 7.875 3.0625 7.875H2.1875C1.94687 7.875 1.75 7.67812 1.75 7.4375V6.5625ZM2.1875 9.625H3.0625C3.30312 9.625 3.5 9.82187 3.5 10.0625V10.9375C3.5 11.1781 3.30312 11.375 3.0625 11.375H2.1875C1.94687 11.375 1.75 11.1781 1.75 10.9375V10.0625C1.75 9.82187 1.94687 9.625 2.1875 9.625ZM5.25 10.5C5.25 10.2594 5.44688 10.0625 5.6875 10.0625H10.0625C10.3031 10.0625 10.5 10.2594 10.5 10.5C10.5 10.7406 10.3031 10.9375 10.0625 10.9375H5.6875C5.44688 10.9375 5.25 10.7406 5.25 10.5ZM5.6875 3.0625H10.0625C10.3031 3.0625 10.5 3.25938 10.5 3.5C10.5 3.74062 10.3031 3.9375 10.0625 3.9375H5.6875C5.44688 3.9375 5.25 3.74062 5.25 3.5C5.25 3.25938 5.44688 3.0625 5.6875 3.0625ZM5.25 7C5.25 6.75938 5.44688 6.5625 5.6875 6.5625H10.0625C10.3031 6.5625 10.5 6.75938 10.5 7C10.5 7.24062 10.3031 7.4375 10.0625 7.4375H5.6875C5.44688 7.4375 5.25 7.24062 5.25 7Z" fill="#0D69D5"/>
                </svg> -->
              </div>
            </div>
          </div>
    
          <div class="rounded-lg w-9 h-full border-2 p-3 mr-2 flex justify-center cursor-pointer" @click="scrollRight">
            <font-awesome-icon icon="chevron-right"  class="text-primary-500 h-6"/>
          </div>
        </div>
    </div>
</template>

<script>
import { capitalize } from "lodash";

export default {
  name: "EntityToolBar",

  data() {
    return {
      scrollAmount: 200 // Amount to scroll each click
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeItemId: {
      type: String,
      default: "",
    },
    caseEntity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    title() {
      return `${capitalize(this.$route.params.caseid)}`;
    },
    caseEntityItemClass() {
      return this.activeItemId === this.caseEntity.id
        ? "cursor-pointer flex items-center gap-6 h-full py-2 px-3 rounded-l-lg text-base font-bold text-primary bg-primary-50 w-44 border-r-2 border-line border-solid"
        : "flex items-center gap-6 h-full py-2 px-3 rounded-l-lg text-base font-bold text-primary w-44 cursor-pointer border-r-2 border-line border-solid";
    },
  },

  methods: {
    scrollLeft() {
      const container = this.$el.querySelector('.scrollable-content');
      container.scrollBy({
        left: -this.scrollAmount,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$el.querySelector('.scrollable-content');
      container.scrollBy({
        left: this.scrollAmount,
        behavior: 'smooth'
      });
    },
    capitalize,
    onEntityItemClick(item) {
      this.$emit("onEntityItemClick", item);
    },
  },
};
</script>

<style scoped lang="scss">
.scrollable-content {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.scrollable-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}
.entity-toolbar {
  &__item {
    position: relative;
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 16px;
      right: 0;
      bottom: 16px;
      width: 4px;
      background-color: var(--theme-color-main);
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 8px;
      right: 16px;
      left: 16px;
      bottom: 8px;
      border-radius: 12px;
      pointer-events: none;
    }

    &--isActive::before {
      background-color: var(--theme-color-primary-50);
    }
  }
}
</style>

import { cloneDeep } from "lodash";
import { checkPermission } from "@shared/utils/functions";
import { FIELDS_TO_CHECK } from "./constants";

export class DataCollection {
    constructor(formData) {
        this.data = formData;
        this.updated = false;
    }
    flag(payload = true) {
        this.updated = payload;
    }
    update(payload = {}) {
        Object.assign(this.data, payload);
        this.flag();
    }
}

export const mapEmails = (data = []) => {
    return data.map((email) => {
        const obj = cloneDeep(email);
        obj.type = email.activity_type;

        switch (obj.type) {
          case "email":
            obj.actions = checkPermission("case.activity_history")
              ? ["reply", "reply-all", "forward", "check"]
              : ["reply", "reply-all", "forward"];
            obj.email_type = email.type;
            break;
          case "email_sequence":
            obj.actions = ["expandSequenceBadge"];
            if (obj.email_delivery_status == false) {
              obj.actions.push("check");
            }
            obj.sequence_id = email.master_sequence_id;
            obj.templates = obj.templates.map((x) => ({
              ...x,
              ...{
                email_from: obj.email_from,
                email_to: obj.email_to,
                sequence_id: x.master_sequence_id,
                html_body: x.body,
              },
            }));
            break;

          case "note":
            obj.actions = checkPermission("case.activity_history")
              ? ["badge", "delete", "check"]
              : ["badge", "delete"];
            if (email?.attachments?.length > 0) {
              obj.actions.splice(1, 0, "attachments");
            }
            break;

          case "call":
            obj.actions = checkPermission("case.activity_history")
              ? ["alarm", "check"]
              : ["alarm"];
            break;

          case "tasks":
            obj.actions = checkPermission("case.activity_history")
              ? ["delete", "edit", "check"]
              : [];
            break;

          default:
            obj.actions = ["check"];
            break;
        }

        return obj;
    });
};

export const formatSections = (data) => {
    data?.data?.entities?.forEach((entity) => {
        let count = 1;
        let resfield = {};

        if (entity?.form_entity_type == "BLOCK") {
            entity?.entities.forEach((field) => {
                if (resfield?.field_internal_name != field.field_internal_name) {
                    count = 1;
                }
                if (field.multi) {
                    field.field_serial = count++;
                }
                resfield = field;
            });
        }

        if (entity?.form_entity_type == "FIELD") {
            if (resfield?.field_internal_name != entity.field_internal_name) {
                count = 1;
            }
            if (entity.multi) {
                entity.field_serial = count++;
            }
            resfield = entity;
        }
    });

    return { sections: [data?.data] };
};

export const formatDataForFormPreview = (data) => {
    return {
        ...data,
        sections: data?.sections?.map((entities) => {
        entities.entities = entities.entities?.map((block) => {
            if (
                block?.form_entity_type == "BLOCK" &&
                block.block_fe_id == null
            ) {
                block.block_fe_id = block?.block_id;
            }

            if (
                block?.form_entity_type == "BLOCK" &&
                block?.block_fe_id == block?.block_id
            ) {
                block.is_og_block = true;
            }
                return block;
            });

            return entities;
        }),
    };
};

export const checkNeedEntitiesUpdate = (sections = []) => {
    for (const section of sections) {
        for (const entity of section?.entities ?? []) {
            for (const child of entity?.entities ?? []) {
                if (FIELDS_TO_CHECK.includes(child?.field_internal_name)) {
                    return true;
                }
            }
        }
    }
};

export const getErrorMessage = (error) => {
    return error.response?.data?.detail[0]?.msg || error.response?.data?.detail;
};
